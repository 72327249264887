import React from 'react'
import {graphql, StaticQuery, Link} from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../containers/layout'
import Container from '../../components/container'
import SEO from '../../components/seo'
// import BuyButton from '../components/BuyButton'

import styles from '../../components/page.module.css'

const ShopApparel = () => (
  <StaticQuery
    query={graphql`
      query {
        productApparel: allSanityProduct(filter: {productCategory: {in: "Apparel"}}) {
          edges {
            node {
              _id
              title
              subTitle
              slug {
                current
              }
              price
              _rawDescription
              productCategory
              productOptions {
                _key
                title
                price
              }
              productImage {
                asset {
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
        shop: sanityShopAll {
          title
          subTitle
          mainImage {
            asset {
              fluid(maxWidth:1900) {
                ...GatsbySanityImageFluid
              }
            }
          }
          seoSettings {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        {data.shop.seoSettings && data.shop.seoSettings.title && data.shop.seoSettings.description && (<SEO title={data.shop.seoSettings.title} description={data.shop.seoSettings.description} />)}

        <Layout mainImage={data.shop.mainImage} title={data.shop.title}>
          <Container>

            <h2>Apparel</h2>
            <div className={styles.productsGrid}>
              {data.productApparel.edges.map(product => (
                <div className={styles.productItem} key={product.node._id}>
                  <div className={styles.productItemContent}>
                    <Link to={`/shop/${product.node.slug.current}`}>
                      <div className={styles.productItemImage}><Img fluid={product.node.productImage.asset.fluid} alt={product.node.title} /></div>
                      <p className={styles.productPrice}>${product.node.price.toFixed(2)}</p>
                      <h3>{product.node.title}</h3>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

          </Container>
        </Layout>
      </>
    )}
  />
)

export default ShopApparel
